<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center">Filtro</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    label="Data"
                                    v-model="date_finalization"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    :time-picker-props="timeProps"
                                    :textFieldProps="{ outlined: true }"
                                    reset-button
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Origem"
                                    :items="possible_origins"
                                    v-model="origin"
                                    :item-text="getText"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    outlined
                                ></v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Tipo"
                                    :items="possible_types"
                                    v-model="type_transaction"
                                    :item-text="getTextType"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    @change="changeType"
                                    outlined
                                >
                                </v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Tipo de Movimentação"
                                    :items="possible_type_movement"
                                    v-model="type_movement"
                                    :item-text="getTextTypeMovement"
                                    item-value="id"
                                    :disabled="movementTypeDisable"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    :hint="hintMessage"
                                    persistent-hint
                                    outlined
                                ></v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="Valor"
                                    v-model="value"
                                    data-vv-name="value"
                                    outlined
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Clube"
                                    :items="slots"
                                    v-model="slot"
                                    :error-messages="errors.collect('slots')"
                                    :item-text="getText"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    outlined
                                ></v-autocomplete>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Banco"
                                    :items="banks"
                                    v-model="bank"
                                    :error-messages="errors.collect('bank')"
                                    :item-text="getTextBank"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    outlined
                                >
                                </v-select>
                            </v-flex>

                            <!-- <v-flex xs12 sm6 md6>
                <v-select
                  label="Origem"
                  :items="possible_origins"
                  v-model="origin"
                  :item-text="getText"
                  item-value="id"
                  :clearable="true"
                  clear-icon="mdi-close"
                  outlined
                ></v-select>
              </v-flex> -->

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Ligação"
                                    :items="possible_vinculations"
                                    v-model="vinculations"
                                    :item-text="getText"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    outlined
                                >
                                </v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Tipos de Baixa"
                                    :items="lowTypes"
                                    v-model="selected_types_low"
                                    item-text="description"
                                    item-value="id"
                                    multiple
                                    small-chips
                                    outlined
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @mousedown.prevent
                                            @click="toggle"
                                        >
                                            <v-list-item-action>
                                                <v-icon
                                                    :color="
                                                        selected_types_low.length >
                                                        0
                                                            ? 'indigo darken-4'
                                                            : ''
                                                    "
                                                >
                                                    {{ icon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Selecionar Tudo
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-select>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn
                        text
                        @click="
                            setShowFilter(false)
                            clearFilter()
                        "
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn text type="submit" right @click="filter"
                        >Filtrar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'PeriodConciliationMovimentsFilter',
    created() {
        this.getBanks()
        this.getSlots({ resetPage: false, noPages: true })
    },

    computed: {
        ...mapState('slots', ['slots']),
        ...mapState('bank', { banks: 'items' }),
        ...mapState('period_conciliation_moviments', [
            'showDialog',
            'selectedItem',
            'possible_types',
            'expense_types',
        ]),

        ...mapFields('period_conciliation_moviments', [
            'filter.date_finalization', //
            'filter.type_movement',
            'filter.type_transaction',
            'filter.value',
            'filter.bank',
            'filter.slot',
            'filter.origin',
            'filter.vinculations',
            'filter.selected_types_low',
        ]),
        ...mapState('period_conciliation_dialog_vinculation', ['lowTypes']),
        selectedAllTypesLow() {
            return this.selected_types_low.length === this.lowTypes.length
        },
        selectedSomeTypeLow() {
            return (
                this.selected_types_low.length > 0 && !this.selectedAllTypesLow
            )
        },
        icon() {
            if (this.selectedAllTypesLow) return 'mdi-close-box'
            if (this.selectedSomeTypeLow) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        ...mapMutations('period_conciliation_moviments', [
            'setShowFilter',
            'clearFilter',
        ]),
        ...mapActions('period_conciliation_moviments', [
            'getItems',
            'getExpenseTypes',
        ]),
        ...mapActions('bank', { getBanks: 'getItems' }),
        ...mapActions('slots', ['getSlots']),

        toggle() {
            this.$nextTick(() => {
                if (this.selectedAllTypesLow) {
                    this.selected_types_low = []
                } else {
                    this.selected_types_low = this.lowTypes.map(
                        (type) => type.id,
                    )
                }
            })
        },
        changeType(item) {
            const changeField = (disable, message) => {
                this.movementTypeDisable = disable
                this.hintMessage = message
            }

            if (item == this.possible_types[0].id) {
                // depósito
                this.possible_type_movement =
                    this.possible_movements_type.deposits
                changeField(false, '')
            } else if (item == this.possible_types[1].id) {
                // saque
                this.possible_type_movement =
                    this.possible_movements_type.withdrawals
                changeField(false, '')
            } else if (item == this.possible_types[2].id) {
                // despesas
                this.getExpenseTypes()
                this.possible_type_movement = this.expense_types
                changeField(false, '')
            } else {
                this.type_movement = ''
                changeField(true, 'Selecione um Tipo para ativar esse campo')
            }
        },

        getText(item) {
            return item.name
        },

        getTextType(item) {
            return item.description
        },

        getTextTypeMovement(item) {
            return item.label
        },

        getTextBank(item) {
            if (item.account) {
                return item.name + ' | ' + item.account
            } else {
                return item.name
            }
        },

        filter() {
            this.getItems({ resetPage: true, type: 0 })
            this.setShowFilter(false)
        },
    },

    data: () => ({
        typeIndex: '',
        movementTypeDisable: true,

        hintMessage: 'Selecione um Tipo para ativar esse campo',

        possible_vinculations: [
            { id: 0, name: 'Sem ligações' },
            { id: 1, name: 'Parcialmente ligado' },
            { id: 2, name: 'Completamente Ligado' },
        ],

        possible_types_transaction: [
            { id: 0, name: 'Retirada' },
            { id: 1, name: 'Envio' },
        ],

        possible_type_movement: [],

        possible_origins: [
            { id: 1, name: 'Depósito Stars' },
            { id: 2, name: 'Saque Stars' },
            { id: 3, name: 'Closure Stars' },
            { id: 5, name: 'Depósito DRX' },
            { id: 6, name: 'Saque DRX' },
            { id: 7, name: 'Closure DRX' },
            { id: 8, name: 'Bônus' },
            { id: 10, name: 'DRX 24h' },
            { id: 11, name: 'Stars 24h' },
            { id: 13, name: 'DRX Central' },
            { id: 14, name: 'Fichas Rapidas' },
            { id: 15, name: 'Venda de Vips' },
        ],

        possible_movements_type: {
            deposits: [
                { id: 1, label: 'Envio Jogador' },
                { id: 2, label: 'Envio Agência' },
                { id: 3, label: 'Abater Anotados' },
                { id: 4, label: 'Adicionar ao Saldo Positivo' },
                { id: 5, label: 'Transferência' },
                { id: 6, label: 'Bônus' },
                { id: 7, label: 'Reembolso' },
            ],
            withdrawals: [
                { id: 1, label: 'Fichas' },
                { id: 2, label: 'Rakeback' },
                { id: 3, label: 'Saldo Positivo' },
                { id: 4, label: 'Transferência' },
                { id: 5, label: 'Ordem Maleta' },
                { id: 6, label: 'Devolução' },
            ],
        },

        timeProps: {
            format: '24hr',
        },
    }),
}
</script>


<style>
html body .v-theme--light,
html body .v-theme--dark {
    --v-disabled-opacity: 0.01;
}
</style>